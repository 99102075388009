import {createRouter, createWebHashHistory} from 'vue-router'
import layout from '../layout/bodyLayout.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/',
    component: layout,
    children: [
      {
        path: '/',
        name: 'index',
        component: () => import('@/views/home/homePage.vue'),
      },
      {
        path: '/industry',
        name: 'industry',
        component:  () => import('@/views/industry/pageIndustry.vue'),
      },
      {
        path: '/service',
        name: 'service',
        component:  () => import('@/views/service/servicePage.vue'),
      },
      {
        path: '/culture',
        name: 'culture',
        component:  () => import('@/views/culture/pageCulture.vue'),
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        component:  () => import('@/views/contactUs/contactUs.vue')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 如果路由元信息中定义了title，则动态更改页面标题
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router
