const TOKEN_KEY = 'token';

const isLogin = () => {
  return !!localStorage.getItem(TOKEN_KEY);
};

const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};
const clearToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.clear();
};
const setUUid = (uuid) => {
  localStorage.setItem('uuid', uuid);
}
const getUUid = () => {
  return localStorage.getItem('uuid');
}
const setCid = (cid) => {
  localStorage.setItem('cid', cid);
}
const getCid = () => {
  if(localStorage.getItem('cid')){
    return localStorage.getItem('cid');
  } else {
    return '10001011'
  }
}
const setVer = (ver) => {
  localStorage.setItem('ver', ver);
}
const gettVer = () => {
  return localStorage.getItem('ver');
}
export { isLogin, getToken, setToken, clearToken, setUUid, getUUid, setCid, getCid, setVer, gettVer };
