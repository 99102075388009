import axios from 'axios';
import { clearToken, getCid, getToken, gettVer, getUUid } from '@/utils/auth';

axios.interceptors.request.use(
  (config) => {
    config.headers = {
      'cid': getCid(),
      'ver': gettVer(),
      'di': getUUid(),
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'sid': getToken(),
    };
    // config.baseURL = 'http://g.jiupiao.vip/';
    // config.baseURL = 'https://sun.jiup9.com/api/';
    // config.baseURL = 'https://t.jiup9.com/';
    // config.baseURL = 'http://test.jiup9.com/api/';
    config.baseURL = process.env.VUE_APP_API_BASE_URL;
    return config;
  },
  (error) => {
    // do something
    return Promise.reject(error);
  },
);
// add response interceptors
axios.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (response.config.responseType !== 'json') {
      return Promise.resolve(response);
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 0) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if ([1001].includes(res.code)) {
        clearToken();
        window.flutter_inappwebview.callHandler('loginIn', '');
      }
      return Promise.resolve(res);
    }
    return Promise.resolve(res);
  },
  (error) => {
    return Promise.reject(error.response);
  },
);
